import {get,post} from "@/utils/axios"

// 获取热门软件、热门硬件
const getPopularSwAndHw = get('api-common/popularRecomen/getPopularSoftware');

// 获取热门云产品
const getPopularRecomenMarket = data => get('api-common/popularRecomen/getPopularRecomenMarket', data);

export default{
    getPopularSwAndHw,
    getPopularRecomenMarket,
}