<template>
<div class="page">
    <my-banner :banner-list="bannerList" :arrow-flag-hide="true" :popularSoftware="popularSoftware" :popularHardware="popularHardware" />
  <el-col class="product-type">
    <el-col class="type1 cursor-item" @click.native="redirectZone(1,$event)">
      <img :src="productTypeList[0].img" class="img">
      <div><span class="name">{{ productTypeList[0].name }}</span></div>
      <div><span class="usName">{{ productTypeList[0].usName }}</span></div>
    </el-col>
    <el-col class="type2 cursor-item" @click.native="redirectZone(2,$event)">
      <img :src="productTypeList[1].img" class="img">
      <div><span class="name">{{ productTypeList[1].name }}</span></div>
      <div><span class="usName">{{ productTypeList[1].usName }}</span></div>
    </el-col>
    <el-col class="type3 cursor-item" @click.native="redirectZone(3,$event)">
      <img :src="productTypeList[2].img" class="img">
      <div><span class="name">{{ productTypeList[2].name }}</span></div>
      <div><span class="usName">{{ productTypeList[2].usName }}</span></div>
    </el-col>
  </el-col>
  <!-- 热门软件专区 -->
  <el-col class="popular-software">
    <el-col class="title">
      <span class="name">热门软件专区</span>
      <span class="usName">Popular&nbsp;application&nbsp;zone</span>
      <el-col>
        <!-- 背景 -->
        <el-col :style='{background: "url(" + popularSwBackground +") no-repeat top center"}' class="bg">
          <el-col class="img-title" >热门软件专区</el-col>
          <el-col class="img-us-title" >Popular application zone</el-col>
          <el-col>
          <a href="/#/SoftwareList"><el-button class="button-views-more" >查看更多</el-button></a>
          </el-col>
        </el-col>
        <!--   软件     -->
        <el-col v-if="popularSoftware.length !=0 && index < 6" v-for="(item,index) in popularSoftware" :key="index"
                :class="'software'+(index+1)" class="cursor-item" @click.native="openProductDetail(item.softwareGoodsId)">
          <img :src="item.imgUrl" class="ps-logo">
          <el-col class="name">{{ item.softwareGoodsName}}</el-col>
          <el-col class="describe">订阅版、乐享售后服务</el-col>
          <el-col class="describe" style="margin: 29px auto">
            <div class="price">
              <span class="amount">¥{{item.goodsPrice}}</span><span class="type">/年</span>
            </div>
          </el-col>
        </el-col>
      </el-col>
    </el-col>
  </el-col>
  <!-- 热门硬件专区 -->
  <el-col class="popular-zone">
    <el-col class="title">
      <span class="name" id="popular-hardwarezone">热门硬件专区</span>
      <span class="usName">Popular&nbsp;application&nbsp;zone</span>
      <el-col>
        <!-- 背景 -->
        <el-col :style='{background: "url(" + popularZone.background +") no-repeat top center"}' class="bg">
          <el-col class="img-title" >热门硬件专区</el-col>
          <el-col class="img-us-title" >Popular application zone</el-col>
          <el-col>
            <a  href="/#/SoftwareList" ><el-button class="button-views-more">查看更多</el-button></a>
          </el-col>
        </el-col>
        <el-col v-if="popularHardware.length != 0" v-for="(item,index) in popularHardware" :key="index"
                :class="'software' + (index+1)" class="cursor-item" @click.native="openProductDetail(item.softwareGoodsId)">
          <img :src="item.imgUrl" class="ps-logo">
          <el-col class="name">{{ item.softwareGoodsName}}</el-col>
          <el-col class="describe">订阅版、乐享售后服务</el-col>
          <el-col class="describe" style="margin: 29px auto">
            <div class="price">
              <span class="amount">¥{{item.goodsPrice}}</span><span class="type">/年</span>
            </div>
          </el-col>
        </el-col>
      </el-col>
    </el-col>
  </el-col>
  <!-- 热门云专区 -->
<!--  <el-col class="popular-cloud">
    <el-col class="title">
      <span class="name" id="popular-cloudzone">热门云专区</span>
      <span class="usName">Popular&nbsp;application&nbsp;zone</span>
      <el-col>
        &lt;!&ndash; 背景 &ndash;&gt;
        <el-col :style='{background: "url(" + popularCloud.background +") no-repeat top center"}' class="bg">
          <el-col class="img-title" >热门云专区</el-col>
          <el-col class="img-us-title" >Popular Cloud
            zone</el-col>
          <el-col>
            <a href="javascript:void(0);"
               @click="redirectUrl('https://www.huaweicloud.com/')">
              <el-button class="button-views-more">查看更多</el-button>
            </a>
          </el-col>
        </el-col>
        &lt;!&ndash; cloud1 &ndash;&gt;
        <el-col class="software1 cursor-item" @click.native="redirectUrl('https://console.huaweicloud.com/ecm/?jumpPage=quickCreate&agencyId=08e8a1f06780f46e1f35c01a7d0ae6e8&region=cn-north-4&locale=zh-cn#/ecs/quickCreateVm')">
          <el-col  class="title">弹性云服务器 ECS</el-col>
          <el-col class="cpu">可随时自助获取、弹性伸</el-col>
          <el-col class="storage">缩的云服务器，多种配置可选，具有高</el-col>
          <el-col class="storage">性价比、易开通、易搭建、易管</el-col>
          <el-col class="storage">理的特点</el-col>
&lt;!&ndash;          <el-col class="qps">{{ popularCloud.cloud1.qps}}</el-col>&ndash;&gt;
          <el-col class="describe" style="margin: 29px auto">
            <div class="price">
              <span class="amount">￥32.20</span><span class="type">/月起</span>
            </div>
          </el-col>
        </el-col>
        &lt;!&ndash; cloud2 &ndash;&gt;
        <el-col class="software2 cursor-item" @click.native="redirectUrl('https://console.huaweicloud.com/ecm/?region=cn-north-4#/evs/createvolume')">
          <el-col  class="title">云硬盘 EVS</el-col>
          <el-col class="cpu">是一种为计算资源提供持</el-col>
          <el-col class="storage">久性块存储的服务，通过数据冗余和缓</el-col>
          <el-col class="storage">存加速等多项技术，提供高可用</el-col>
          <el-col class="storage">性和持久化性</el-col>
&lt;!&ndash;          <el-col class="qps">{{ popularCloud.cloud2.qps}}</el-col>&ndash;&gt;
          <el-col class="describe" style="margin: 29px auto">
            <div class="price">
              <span class="amount">￥0.35/GB</span><span class="type">/月起</span>
            </div>
          </el-col>
        </el-col>
        <el-col>
          &lt;!&ndash; cloud3 &ndash;&gt;
          <el-col class="software3 cursor-item" @click.native="redirectUrl('https://storage.huaweicloud.com/obs/?region=cn-north-4&locale=zh-cn#/obs/buy')">
            <el-col  class="title">对象存储服务 OBS</el-col>
            <el-col class="cpu">一款稳定、安全、高效、</el-col>
            <el-col class="storage">易用的云存储服务，具备标准RESTful</el-col>
            <el-col class="storage">API接口，可存储任意数量和形</el-col>
            <el-col class="storage">式的非结构化数据</el-col>
&lt;!&ndash;            <el-col class="qps">{{ popularCloud.cloud3.qps}}</el-col>&ndash;&gt;
            <el-col class="describe" style="margin: 29px auto">
              <div class="price">
                <span class="amount">￥0.099/GB</span><span class="type">/月起</span>
              </div>
            </el-col>
          </el-col>
          &lt;!&ndash; cloud4 &ndash;&gt;
          <el-col class="software4 cursor-item" @click.native="redirectUrl('https://console.huaweicloud.com/rds/?Engine=mysql&region=cn-north-4#/rds/createIns/')">
            <el-col  class="title">云数据库 MySQL</el-col>
            <el-col class="cpu"> RDS for MySQL是稳定</el-col>
            <el-col class="storage">可靠、可弹性伸缩的、云托管的云数据</el-col>
            <el-col class="storage">库服务。通过云数据库能够让您</el-col>
            <el-col class="storage">几分钟内完成数据库部署</el-col>
&lt;!&ndash;            <el-col class="qps">{{ popularCloud.cloud4.qps}}</el-col>&ndash;&gt;
            <el-col class="describe" style="margin: 29px auto">
              <div class="price">
                <span class="amount">￥250</span><span class="type">/月起</span>
              </div>
            </el-col>
          </el-col>
          &lt;!&ndash; cloud5、6 &ndash;&gt;
          <el-col v-if="popularCloudLast.length == 2" v-for="(item,index) in popularCloudLast" :key="index"
                  :class="index ==0 ? 'software5':'software6'" class="cursor-item" @click.native="openCloudMarket(item.marketId)">
            <el-col  class="title">{{item.name}}</el-col>
            <el-col class="cpu">{{item.profile}}</el-col>
            <el-col class="describe" style="margin: 29px auto">
              <div class="price">
                <span class="amount">￥{{item.extend2 == null ? '0' : item.extend2}}</span>
                <span class="type">{{item.extend3 == 1 ? '/次' : item.extend3 == 2 ? '/月' : item.extend3 == 3 ? '/年' : '试用'}}</span>
              </div>
            </el-col>
          </el-col>
        </el-col>
      </el-col>


    </el-col>
  </el-col>-->
</div>
</template>

<script>
import Banner from '../../compoents/banner/banner-components'
import homeIndex from '../../api/homeIndex'

export default {
  name: "HomeIndex",
  components: {
    'my-banner':Banner
  },
  data() {
    return {
      bannerList: [
        {
          img: require("@/img/home/banner1.png"),
          writtenWords: {
            title: '伊登云资讯动态',
            describe: 'TRENDS OF EDEN CLOUD INFORMATION'
          },
          align: 'left',
          imgNum: 'single-img',
          color: 'white-font'
        },
        {
          img: require("@/img/home/banner1.png"),
          writtenWords: {
            title: '伊登云资讯动态',
            describe: 'TRENDS OF EDEN CLOUD INFORMATION'
          },
          align: 'left',
          imgNum: 'single-img',
          color: 'white-font'
        },
        {
          img: require("@/img/home/banner1.png"),
          writtenWords: {
            title: '伊登云资讯动态',
            describe: 'TRENDS OF EDEN CLOUD INFORMATION'
          },
          align: 'left',
          imgNum: 'single-img',
          color: 'white-font'
        }
      ],
      productTypeList: [
        {
          name: '软件专区',
          usName: 'Novice Benefits',
          img: require("@/img/home/software.png")
        },
        {
          name: '云市场',
          usName: 'Cloud Marketplace',
          img: require("@/img/home/cloud_product.png")
        },
        {
          name: '解决方案',
          usName: 'Solution',
          img: require("@/img/home/solution.png")
        },

      ],
      popularGoods:[],
      popularSwBackground: require("@/img/home/popular-application.png"),
      popularSoftware: [],
      popularHardware: [],
      popularZone: {
        background:  require("@/img/home/zoneBg.png"),
        book1: {
          name: 'Surface Book 增强版',
          logo: require("@/img/home/surface-book.png"),
          describe: '订阅版、乐享售后服务',
          amount: '¥9999',
          type: '/年'
        },
        book2: {
          name: 'Surface Book 增强版',
          logo: require("@/img/home/book.png"),
          describe: '订阅版、乐享售后服务',
          amount: '¥9999',
          type: '/年'
        },
        book3: {
          name: 'Surface Book 增强版',
          logo: require("@/img/home/surface-book.png"),
          describe: '订阅版、乐享售后服务',
          amount: '¥9999',
          type: '/年'
        },
        book4: {
          name: 'Surface Book 增强版',
          logo: require("@/img/home/book.png"),
          describe: '订阅版、乐享售后服务',
          amount: '¥9999',
          type: '/年'
        },
        book5: {
          name: 'Surface Book 增强版',
          logo: require("@/img/home/SurfaceHome_Lg_ImagePanel_3_V1.png"),
          describe: '订阅版、乐享售后服务',
          amount: '¥9999',
          type: '/年'
        },
        book6: {
          name: 'Surface Book 增强版',
          logo: require("@/img/home/SurfaceHome_Lg_ImagePanel_1_V1.png"),
          describe: '订阅版、乐享售后服务',
          amount: '¥9999',
          type: '/年'
        }
      },
      popularCloud: {
        background: require("@/img/home/cloud-bg.png")
      },
      popularCloudLast: [],
    }
  },
  created() {
    console.log("test")
    //this.getPopularSoftware();
  },
  mounted() {
    this.$store.commit('setCheckIndex', 1);
    this.getPopularSoftware();
  },
  methods: {
    // 获取热门软件、热门硬件
    getPopularSoftware(){
      this.$nextTick(()=>{
        let obj = this;
        homeIndex.getPopularSwAndHw.then(res => {
          if (res.code == 200) {
            obj.popularGoods = res.data;
            console.log('ceshi');
            console.log(res.data)
            if (obj.popularGoods.length > 0) {
              obj.popularSoftware = [];
              obj.popularHardware = [];
              for (var j = 0; j < obj.popularGoods.length; j++) {
                //软件
                if (obj.popularGoods[j].softwareGoodsType == 1) {
                  obj.popularSoftware[j] = obj.popularGoods[j];
                  //硬件
                } else if (obj.popularGoods[j].softwareGoodsType == 2) {
                  obj.popularHardware.push(obj.popularGoods[j]);
                }
              }
            }
          }
          obj.getPopularRecomenMarket();
        })
      })


    },
    // 获取热门云专区 最后两个云产品
    getPopularRecomenMarket(){
      let obj = this;
      homeIndex.getPopularRecomenMarket({
        "pageNo": 0,
        "pageSize": 2
      }).then(res => {
        if (res.code == 200) {
          console.log(res.data);
          obj.popularCloudLast = res.data;
        }
      })

    },
    // 重定向页面
    redirectUrl(url){
      window.open("https://auth.huaweicloud.com/authui/saml/login?xAccountType=EDENSOFT_2013_IDP&isFirstLogin=false&service=" + encodeURIComponent(url));
    },
    // 跳转到产品详情页
    openProductDetail(goodsId){
      this.$store.commit('setCheckIndex', 2);
      this.$router.push({path: `/ProductDescription`,query:{productId: goodsId}});
    },
    // 跳转云产品详情页
    openCloudMarket(marketId) {
      console.log(marketId)
      if(marketId != '') {
        this.$router.push({path: '/cloudMarket',query:{productId: marketId, orderType: 1}})
      }
      return
    },
    // 软件、硬件、云产品专区跳转
    redirectZone(type,e) {
      // 软件专区
      if(type == 1) {
          this.$store.commit('setCheckIndex', 2);
          this.$router.push({path: '/softwareList'});
      } else if (type == 2) {
        // 云产品  跳转到华为虚拟机
        this.$store.commit('setCheckIndex', 3);
        this.$router.push({path: '/cloudMarket', query:{productId: 'adadada9-f9b8-482c-b378-fc1e0a9b5617', orderType: 1}})
      } else {
        // 新手福利
        this.$store.commit('setCheckIndex', 4);
        this.$router.push({path: '/home'})
      }
    },
    // 跳转到华为
    redirectHuawei(url){
      window.open("https://auth.huaweicloud.com/authui/saml/login?xAccountType=EDENSOFT_2013_IDP&isFirstLogin=false&service=" + encodeURIComponent(url));
    },
  }
}
</script>

<style lang="less" scoped>
.cursor-item {
    cursor: pointer;
}
.page {
  height: 2620px;
  background: #F7F7F7;
}
.product-type {
  width: 1201px;
  height: 214px;
  //background: #F7F7F7;
  margin: 0px auto;
  float: unset;
  .type1 {

    .img {
      width: 141px;
      height: 94px;
      margin: 46px 0px 49px 35px;
    }
    .name {
      width: 96px;
      height: 31px;
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 31px;
      margin: -119px 88px 0px 0px;
      float: right;
    }
    .usName {
      width: 149px;
      height: 26px;
      font-size: 20px;
      color: #737373;
      line-height: 26px;
      margin: -80px 35px 0px 0px;
      float: right;
    }
    width: 395px;
    height: 214px;
    background: #FFFFFF;
    margin: 20px 0px 0px 0px;
    transition: all .2s linear;
  }
  .type1:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;
    box-shadow: 0 15px 30px #d0d0d0;

  }.type2:hover {
    transform: translate3d(0,-2px,0);
    box-shadow: 0 15px 30px #d0d0d0;
  }.type3:hover {
    transform: translate3d(0,-2px,0);
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .type2 {
    .img {
      width: 95px;
      height: 117px;
      margin: 46px 0px 49px 35px;
    }
    .name {
      width: 120px;
      height: 31px;
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 31px;
      margin: -144px 87px   0px 0px;
      float: right;
    }
    .usName {
      width: auto;
      height: 26px;
      font-size: 20px;
      color: #737373;
      line-height: 26px;
      margin: -107px 26px  0px 0px;
      float: right;
    }
    width: 395px;
    height: 214px;
    background: #FFFFFF;
    margin: 20px 0px 0px 8px;
    transition: all .2s linear;
  }
  .type3 {
    .img {
      width: 95px;
      height: 117px;
      margin: 46px 0px 49px 35px;;
    }
    .name {
      width: 120px;
      height: 31px;
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 31px;
      margin: -139px  88px 0px 0px;
      float: right;
    }
    .usName {
      width: 157px;
      height: 26px;
      font-size: 20px;
      color: #737373;
      line-height: 26px;
      margin: -99px 49px 0px 0px;
      float: right;
    }
    width: 395px;
    height: 214px;
    background: #FFFFFF;
    margin: 20px 0px 0px 8px;
    transition: all .2s linear;
  }
}
.popular-software {
  width: 1201px;
  height: 600px;
  margin: 40px auto;
  float: unset;
  .bg:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;;
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .bg {
    width: 596px;
    height: 380px;
    transition: all .2s linear;
    .img-title {
      width: 192px;
      height: 40px;
      font-size: 30px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      margin: 65px 0px 0px 39px;
    }
    .img-us-title {
      width: 192px;
      height: 120px;
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      clear: both;
      margin: 30px 0px 0px 39px;
    }
     /deep/ .el-button {
      font-size: 14px;
      line-height: 10px;
    }
    .button-views-more {
      width: 73px;
      height: 30px;
      border-radius: 10px;
      border: 1px solid #FFFFFF;
      padding: 9px;
      background: none;
      color: #FFFFFF;
      margin: 30px 0px 0px 39px;
    }
  }
  .name {
    margin: 32px 0px 43px 0px;
    width: 180px;
    height: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    line-height: 40px;
    float: left;
  }
  .usName {
    width: 352px;
    height: 31px;
    font-size: 24px;
    color: #414141;
    line-height: 31px;
    float: left;
    margin-top: 39px;
    margin-left: 18px;
  }
  .software1:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;;
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .software1 {
    width: 294px;
    height: 380px;
    background: #FFFFFF;
    margin: -380px 0px 0px 604px;
    transition: all .2s linear;
    display: flex;
    text-align: center;
    flex-direction: column;
    .ps-logo {
       margin: 77px  106px 0px 106px;
       width: 90px;
      height: 90px;
      /*margin-top: 90px;*/
    }
    .name {
      width: 100%;
      font-size: 18px;
      height: 40px;
      padding: 0 20px;
      font-weight: bold;
      color: #101010;
      line-height: 21px;
      /* margin: 35px 63px 0px 63px; */
      margin-top: 35px;
      margin-bottom: 0;
    }
    .describe {
      /* width: 120px; */
      height: 16px;
      font-size: 12px;
      color: #414141;
      line-height: 16px;
      /* margin: 20px 87px 0px 87px;*/
        .consult{
          font-size: 24px;
          font-weight: bold;
          color: #D60010;
          line-height: 31px;
        }
      }
      .price {
        height: 31px;
        /* width: 83px; */
        /* margin: 0px 105px 0px 106px;*/
            .amount {
              width: 83px;
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              width: 28px;
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }

        }
        .software2:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;

        }
        .software2 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: -380px 0px 0px 906px;
          transition: all .2s linear;
          display: flex;
          text-align: center;
          flex-direction: column;
          .ps-logo {
            margin: 77px  106px 0px 106px;
            width: 90px;
            height: 90px;
            /*margin-top: 90px;*/
          }
          .name {
            width: 260px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 21px;
            font-size: 18px;
            height: 40px;
            padding: 0 20px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 35px 63px 0px 63px; */
            margin-top: 35px;
            margin-bottom: 0;

          }
          .describe {
            /* width: 120px; */
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;

            /* margin: 20px 87px 0px 87px;*/
            .consult{
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
            }
          }
          .price {
            height: 31px;
            /* width: 83px; */
            /* margin: 0px 105px 0px 106px;*/
            .amount {
              width: 83px;
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              width: 28px;
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software3:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software3 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 8px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          text-align: center;
          flex-direction: column;
          .ps-logo {
            margin: 77px  106px 0px 106px;
            width: 90px;
            height: 90px;
            /*margin-top: 90px;*/
          }
          .name {
            width: 260px;
            font-size: 18px;
            height: 40px;
            padding: 0 20px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 35px 63px 0px 63px; */
            margin-top: 35px;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 21px;

          }
          .describe {
            /* width: 120px; */
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /* margin: 20px 87px 0px 87px;*/
            .consult{
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
            }
          }
          .price {
            height: 31px;
            /* width: 83px; */
            /* margin: 0px 105px 0px 106px;*/
            .amount {
              width: 83px;
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              width: 28px;
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software4:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software4 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 8px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          text-align: center;
          flex-direction: column;
          .ps-logo {
            margin: 77px  106px 0px 106px;
            width: 90px;
            height: 90px;
            /*margin-top: 90px;*/
          }
          .name {
            width: 100%;
            font-size: 18px;
            height: 40px;
            padding: 0 20px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 35px 63px 0px 63px; */
            margin-top: 35px;
            margin-bottom: 0;
          }
          .describe {
            /* width: 120px; */
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /* margin: 20px 87px 0px 87px;*/
            .consult{
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
            }
          }
          .price {
            height: 31px;
            /* width: 83px; */
            /* margin: 0px 105px 0px 106px;*/
            .amount {
              width: 83px;
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              width: 28px;
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software5:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software5 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 8px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          text-align: center;
          flex-direction: column;
          .ps-logo {
            margin: 77px  106px 0px 106px;
            width: 90px;
            height: 90px;
            /*margin-top: 90px;*/
          }
          .name {
            width: 260px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 21px;
            font-size: 18px;
            height: 40px;
            padding: 0 20px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 35px 63px 0px 63px; */
            margin-top: 35px;
            margin-bottom: 0;
          }
          .describe {
            /* width: 120px; */
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /* margin: 20px 87px 0px 87px;*/
            .consult{
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
            }
          }
          .price {
            height: 31px;
            /* width: 83px; */
            /* margin: 0px 105px 0px 106px;*/
            .amount {
              width: 83px;
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              width: 28px;
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software6:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software6 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 0px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          text-align: center;
          flex-direction: column;
          .ps-logo {
            margin: 77px  106px 0px 106px;
            width: 90px;
            height: 90px;
            /*margin-top: 90px;*/
          }
          .name {
            width: 100%;
            font-size: 18px;
            height: 40px;
            padding: 0 20px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 35px 63px 0px 63px; */
            margin-top: 35px;
            margin-bottom: 0;
          }
          .describe {
            /* width: 120px; */
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /* margin: 20px 87px 0px 87px;*/
            .consult{
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
            }
          }
          .price {
            height: 31px;
            /* width: 83px; */
            /* margin: 0px 105px 0px 106px;*/
            .amount {
              width: 83px;
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              width: 28px;
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
      }
      .popular-zone {
        width: 1201px;
        height: 600px;
        margin: 40px auto;
        float: unset;
        .bg:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .bg {
          width: 596px;
          height: 380px;
          transition: all .2s linear;
          .img-title {
            width: 192px;
            height: 40px;
            font-size: 30px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 40px;
            margin: 65px 0px 0px 39px;
          }
          .img-us-title {
            width: 192px;
            height: 120px;
            font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 40px;
            clear: both;
            margin: 30px 0px 0px 39px;
          }
           /deep/ .el-button {
            font-size: 14px;
            line-height: 10px;
          }
          .button-views-more {
            width: 73px;
            height: 30px;
            border-radius: 10px;
            border: 1px solid #FFFFFF;
            padding: 9px;
            background: none;
            color: #FFFFFF;
            margin: 30px 0px 0px 39px;
          }
        }
        .name {
          margin: 32px 0px 43px 0px;
          width: 180px;
          height: 40px;
          font-size: 30px;
          font-weight: bold;
          color: #000000;
          line-height: 40px;
          float: left;
        }
        .usName {
          width: 352px;
          height: 31px;
          font-size: 24px;
          color: #414141;
          line-height: 31px;
          float: left;
          margin-top: 39px;
          margin-left: 18px;
        }
        .software1:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software1 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: -380px 0px 0px 604px;
          transition: all .2s linear;
          display: flex;
          flex-direction: column;
          text-align: center;
          .ps-logo {
            margin: 51px 69px 0px 69px;
            width: 157px;
            height: 116px;
          }
          .name {
            width: 100%;
            height: 40px;
            font-size: 18px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 40px 63px 0px 63px; */
            margin-top: 40px;
            margin-bottom: 0px;
            padding: 0 20px;
          }
          .describe {
            /*width: 120px;*/
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /*margin: 20px 87px 0px 87px;*/
          }
          .price {
            height: 31px;
            /*width: 102px;*/
            /*margin: 0px 105px 0px 106px;*/
            .amount {
              /*width: 83px;*/
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              /*width: 28px;*/
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }

        }
        .software2:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software2 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: -380px 0px 0px 906px;
          transition: all .2s linear;
          display: flex;
          flex-direction: column;
          text-align: center;
          .ps-logo {
            margin: 51px 69px 0px 69px;
            width: 157px;
            height: 116px;
          }
          .name {
            width: 100%;
            height: 40px;
            font-size: 18px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 40px 63px 0px 63px; */
            margin-top: 40px;
            margin-bottom: 0px;
            padding: 0 20px;
          }
          .describe {
            /*width: 120px;*/
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /*margin: 20px 87px 0px 87px;*/
          }
          .price {
            height: 31px;
            /*width: 102px;*/
            /*margin: 0px 105px 0px 106px;*/
            .amount {
              /*width: 83px;*/
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              /*width: 28px;*/
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software3:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software3 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 8px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          flex-direction: column;
          text-align: center;
          .ps-logo {
            margin: 51px 69px 0px 69px;
            width: 157px;
            height: 116px;
          }
          .name {
            width: 100%;
            height: 40px;
            font-size: 18px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 40px 63px 0px 63px; */
            margin-top: 40px;
            margin-bottom: 0px;
            padding: 0 20px;
          }
          .describe {
            /*width: 120px;*/
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /*margin: 20px 87px 0px 87px;*/
          }
          .price {
            height: 31px;
            /*width: 102px;*/
            /*margin: 0px 105px 0px 106px;*/
            .amount {
              /*width: 83px;*/
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              /*width: 28px;*/
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software4:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software4 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 8px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          flex-direction: column;
          text-align: center;
          .ps-logo {
            margin: 51px 69px 0px 69px;
            width: 157px;
            height: 116px;
          }
          .name {
            width: 100%;
            height: 40px;
            font-size: 18px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 40px 63px 0px 63px; */
            margin-top: 40px;
            margin-bottom: 0px;
            padding: 0 20px;
          }
          .describe {
            /*width: 120px;*/
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /*margin: 20px 87px 0px 87px;*/
          }
          .price {
            height: 31px;
            /*width: 102px;*/
            /*margin: 0px 105px 0px 106px;*/
            .amount {
              /*width: 83px;*/
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              /*width: 28px;*/
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software5:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software5 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 8px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          flex-direction: column;
          text-align: center;
          .ps-logo {
            margin: 51px 69px 0px 69px;
            width: 157px;
            height: 116px;
          }
          .name {
            width: 100%;
            height: 40px;
            font-size: 18px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 40px 63px 0px 63px; */
            margin-top: 40px;
            margin-bottom: 0px;
            padding: 0 20px;
          }
          .describe {
            /*width: 120px;*/
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /*margin: 20px 87px 0px 87px;*/
          }
          .price {
            height: 31px;
            /*width: 102px;*/
            /*margin: 0px 105px 0px 106px;*/
            .amount {
              /*width: 83px;*/
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              /*width: 28px;*/
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
        .software6:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software6 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: 7px 0px 0px 0px ;
          transition: all .2s linear;
          display: flex;
          flex-direction: column;
          text-align: center;
          .ps-logo {
            margin: 51px 69px 0px 69px;
            width: 157px;
            height: 116px;
          }
          .name {
            width: 100%;
            height: 40px;
            font-size: 18px;
            font-weight: bold;
            color: #101010;
            line-height: 21px;
            /* margin: 40px 63px 0px 63px; */
            margin-top: 40px;
            margin-bottom: 0px;
            padding: 0 20px;
          }
          .describe {
            /*width: 120px;*/
            height: 16px;
            font-size: 12px;
            color: #414141;
            line-height: 16px;
            /*margin: 20px 87px 0px 87px;*/
          }
          .price {
            height: 31px;
            /*width: 102px;*/
            /*margin: 0px 105px 0px 106px;*/
            .amount {
              /*width: 83px;*/
              height: 31px;
              font-size: 24px;
              font-weight: bold;
              color: #D60010;
              line-height: 31px;
              //margin: 0px 0px 0px 106px;
            }
            .type {
              /*width: 28px;*/
              height: 25px;
              font-size: 15px;
              font-weight: bold;
              color: #D60010;
              line-height: 25px;
            }
          }
        }
      }
      .popular-cloud {
        width: 1201px;
        height: 600px;
        margin: 40px auto;
        float: unset;
        .bg:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .bg {
          width: 596px;
          height: 380px;
          transition: all .2s linear;
          .img-title {
            width: 192px;
            height: 40px;
            font-size: 30px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 40px;
            margin: 85px 0px 0px 39px;
          }
          .img-us-title {
            width: 192px;
            height: 80px;
            font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 40px;
            clear: both;
            margin: 30px 0px 0px 39px;
          }
          /deep/ .el-button {
            font-size: 14px;
            line-height: 10px;
          }
          .button-views-more {
            width: 73px;
            height: 30px;
            border-radius: 10px;
            border: 1px solid #FFFFFF;
            padding: 9px;
            background: none;
            color: #FFFFFF;
            margin: 30px 0px 0px 39px;
          }
        }
        .name {
          margin: 32px 0px 43px 0px;
          width: 180px;
          height: 40px;
          font-size: 30px;
          font-weight: bold;
          color: #000000;
          line-height: 40px;
          float: left;
        }
        .usName {
          width: 352px;
          height: 31px;
          font-size: 24px;
          color: #414141;
          line-height: 31px;
          float: left;
          margin-top: 39px;
          margin-left: 18px;
        }
        .software1:hover {
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-2px,0);
          -webkit-box-shadow: 0 15px 30px #d0d0d0;
          box-shadow: 0 15px 30px #d0d0d0;
        }
        .software1 {
          width: 294px;
          height: 380px;
          background: #FFFFFF;
          margin: -380px 0px 0px 604px;
          -webkit-transition: all .2s linear;
          transition: all .2s linear;
          display: flex;
          text-align: center;
          flex-direction: column;
          .title {
            /*width: 201px;*/
      height: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #414141;
      line-height: 26px;
      /*margin: 64px 46px 0px 46px;*/
      margin-top: 64px;
    }
    .cpu {
      /*width: 53px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 45px 121px 0px 121px;*/
      margin-top: 45px;
    }
    .storage {
      /*width: 92px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 23px 101px 0px 101px;*/
      margin-top: 23px;
    }
    .qps {
      /*width: 50px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 32px 122px 0px 122px;*/
      margin-top: 32px;
    }
    .describe {
      /*width: 120px;*/
      height: 16px;
      font-size: 12px;
      color: #414141;
      line-height: 16px;
      /*margin: 20px 87px 0px 87px;*/
      margin-top: 20px;
    }
    .price {
      height: 31px;
      /*width: 102px;*/
      /*margin: 0px 105px 0px 106px;*/
      .amount {
        width: 83px;
        height: 31px;
        font-size: 24px;
        font-weight: bold;
        color: #D60010;
        line-height: 31px;
        //margin: 0px 0px 0px 106px;
      }
      .type {
        width: 28px;
        height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: #D60010;
        line-height: 25px;
      }
    }

  }
  .software2:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .software2 {
    width: 294px;
    height: 380px;
    background: #FFFFFF;
    margin: -380px 0px 0px 906px;
    transition: all .2s linear;
    display: flex;
    text-align: center;
    flex-direction: column;
    .title {
      /*width: 201px;*/
      height: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #414141;
      line-height: 26px;
      /*margin: 64px 46px 0px 46px;*/
      margin-top: 64px;
    }
    .cpu {
      /*width: 53px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 45px 121px 0px 121px;*/
      margin-top: 45px;
    }
    .storage {
      /*width: 92px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 23px 101px 0px 101px;*/
      margin-top: 23px;
    }
    .qps {
      /*width: 50px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 32px 122px 0px 122px;*/
      margin-top: 32px;
    }
    .describe {
      /*width: 120px;*/
      height: 16px;
      font-size: 12px;
      color: #414141;
      line-height: 16px;
      /*margin: 20px 87px 0px 87px;*/
      margin-top: 20px;
    }
    .price {
      height: 31px;
      /*width: 102px;*/
      /*margin: 0px 105px 0px 106px;*/
      .amount {
        width: 83px;
        height: 31px;
        font-size: 24px;
        font-weight: bold;
        color: #D60010;
        line-height: 31px;
        //margin: 0px 0px 0px 106px;
      }
      .type {
        width: 28px;
        height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: #D60010;
        line-height: 25px;
      }
    }
  }
  .software3:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .software3 {
    width: 294px;
    height: 380px;
    background: #FFFFFF;
    margin: 7px 8px 0px 0px ;
    transition: all .2s linear;
    display: flex;
    text-align: center;
    flex-direction: column;
    .title {
      /*width: 201px;*/
      height: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #414141;
      line-height: 26px;
      /*margin: 64px 46px 0px 46px;*/
      margin-top: 64px;
    }
    .cpu {
      /*width: 53px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 45px 121px 0px 121px;*/
      margin-top: 45px;
    }
    .storage {
      /*width: 92px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 23px 101px 0px 101px;*/
      margin-top: 23px;
    }
    .qps {
      /*width: 50px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 32px 122px 0px 122px;*/
      margin-top: 32px;
    }
    .describe {
      /*width: 120px;*/
      height: 16px;
      font-size: 12px;
      color: #414141;
      line-height: 16px;
      /*margin: 20px 87px 0px 87px;*/
      margin-top: 20px;
    }
    .price {
      height: 31px;
      /*width: 102px;*/
      /*margin: 0px 105px 0px 106px;*/
      .amount {
        width: 83px;
        height: 31px;
        font-size: 24px;
        font-weight: bold;
        color: #D60010;
        line-height: 31px;
        //margin: 0px 0px 0px 106px;
      }
      .type {
        width: 28px;
        height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: #D60010;
        line-height: 25px;
      }
    }
  }
  .software4:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .software4 {
    width: 294px;
    height: 380px;
    background: #FFFFFF;
    margin: 7px 8px 0px 0px ;
    transition: all .2s linear;
    display: flex;
    text-align: center;
    flex-direction: column;
    .title {
      /*width: 201px;*/
      height: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #414141;
      line-height: 26px;
      /*margin: 64px 46px 0px 46px;*/
      margin-top: 64px;
    }
    .cpu {
      /*width: 53px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 45px 121px 0px 121px;*/
      margin-top: 45px;
    }
    .storage {
      /*width: 92px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 23px 101px 0px 101px;*/
      margin-top: 23px;
    }
    .qps {
      /*width: 50px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 32px 122px 0px 122px;*/
      margin-top: 32px;
    }
    .describe {
      /*width: 120px;*/
      height: 16px;
      font-size: 12px;
      color: #414141;
      line-height: 16px;
      /*margin: 20px 87px 0px 87px;*/
      margin-top: 20px;
    }
    .price {
      height: 31px;
      /*width: 102px;*/
      /*margin: 0px 105px 0px 106px;*/
      .amount {
        width: 83px;
        height: 31px;
        font-size: 24px;
        font-weight: bold;
        color: #D60010;
        line-height: 31px;
        //margin: 0px 0px 0px 106px;
      }
      .type {
        width: 28px;
        height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: #D60010;
        line-height: 25px;
      }
    }
  }
  .software5:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .software5 {
    width: 294px;
    height: 380px;
    background: #FFFFFF;
    margin: 7px 8px 0px 0px ;
    transition: all .2s linear;
    display: flex;
    text-align: center;
    flex-direction: column;
    .title {
      /*width: 201px;*/
      height: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #414141;
      line-height: 26px;
      /*margin: 64px 46px 0px 46px;*/
      margin-top: 64px;
    }
    .cpu {
      width: 235px;
      height: 145px;
      text-align: center;
      font-size: 14px;
      color: #414141;
      line-height: 38px;
      margin-top: 36px;
      margin-left: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .storage {
      /*width: 92px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 23px 101px 0px 101px;*/
      margin-top: 23px;
    }
    .qps {
      /*width: 50px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 32px 122px 0px 122px;*/
      margin-top: 32px;
    }
    .describe {
      /*width: 120px;*/
      height: 16px;
      font-size: 12px;
      color: #414141;
      line-height: 16px;
      /*margin: 20px 87px 0px 87px;*/
      margin-top: 20px;
    }
    .price {
      height: 31px;
      /*width: 102px;*/
      /*margin: 0px 105px 0px 106px;*/
      .amount {
        width: 83px;
        height: 31px;
        font-size: 24px;
        font-weight: bold;
        color: #D60010;
        line-height: 31px;
        //margin: 0px 0px 0px 106px;
      }
      .type {
        width: 28px;
        height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: #D60010;
        line-height: 25px;
      }
    }
  }
  .software6:hover {
    -webkit-transform: translate3d(0,-2px,0);
    transform: translate3d(0,-2px,0);
    -webkit-box-shadow: 0 15px 30px #d0d0d0;
    box-shadow: 0 15px 30px #d0d0d0;
  }
  .software6 {
    width: 294px;
    height: 380px;
    background: #FFFFFF;
    margin: 7px 0px 0px 0px ;
    transition: all .2s linear;
    display: flex;
    text-align: center;
    flex-direction: column;
    .title {
      /*width: 201px;*/
      height: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #414141;
      line-height: 26px;
      /*margin: 64px 46px 0px 46px;*/
      margin-top: 64px;
    }
    .cpu {
      width: 235px;
      height: 145px;
      text-align: center;
      font-size: 14px;
      color: #414141;
      line-height: 38px;
      margin-top: 36px;
      margin-left: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .storage {
      /*width: 92px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 23px 101px 0px 101px;*/
      margin-top: 23px;
    }
    .qps {
      /*width: 50px;*/
      height: 19px;
      font-size: 14px;
      color: #414141;
      line-height: 19px;
      /*margin: 32px 122px 0px 122px;*/
      margin-top: 32px;
    }
    .describe {
      /*width: 120px;*/
      height: 16px;
      font-size: 12px;
      color: #414141;
      line-height: 16px;
      /*margin: 20px 87px 0px 87px;*/
      margin-top: 20px;
    }
    .price {
      height: 31px;
      /*width: 102px;*/
      /*margin: 0px 105px 0px 106px;*/
      .amount {
        width: 83px;
        height: 31px;
        font-size: 24px;
        font-weight: bold;
        color: #D60010;
        line-height: 31px;
        //margin: 0px 0px 0px 106px;
      }
      .type {
        width: 28px;
        height: 25px;
        font-size: 15px;
        font-weight: bold;
        color: #D60010;
        line-height: 25px;
      }
    }
  }
}
</style>
